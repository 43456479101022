<template>
	<div ref="rWrap" :class="['bottom-content', 'drag-bottom1']">
		<div class="title">
			<div ref="rResizeLine" id="resize" class="resize-line" @mousedown="handleMouseDown"></div>
			<div class="btn-list">
				<img @click="closeBottom" src="@/assets/img/icon-close.png" alt="" />
			</div>
		</div>
		<el-table
			class="bottom-table"
			:data="reportTable"
			@row-click="selectRow"
			style="width: 100%"
			:row-class-name="tableRowClassName"
			ref="bottomTable"
			size="small"
			border
			header-row-class-name="table-header"
			:max-height="getMaxHeight"
			:height="tableHeight"
		>
			<el-table-column fixed prop="Seg" label="Seg" align="center">
				<template slot-scope="scope">
					<el-popover
						v-if="scope.row.masterReportInfo.notice && scope.row.masterReportInfo.notice.length"
						placement="top-start"
						width="200"
						trigger="hover"
						popper-class="monitor-yt-popover"
					>
						<div>
							<p v-for="data in scope.row.masterReportInfo.notice" :key="data">{{ data }}</p>
						</div>
						<div slot="reference" class="imgNumber">
							<img src="@/assets/right-img/notice-red.png" width="16px" />
							{{ scope.row.masterReportInfo.seg ? scope.row.masterReportInfo.seg : '' }}
						</div>
					</el-popover>
					<div class="imgNumber" v-else>
						<!-- <img src="@/assets/right-img/notice.png" width="16px" /> -->
						{{ scope.row.masterReportInfo.seg ? scope.row.masterReportInfo.seg : '' }}
					</div>
				</template>
			</el-table-column>
			<el-table-column
				v-for="data in fieldList"
				:key="data.fieldName"
				:prop="data.prop"
				:label="data.fieldName"
				:fixed="data.fixed ? data.fixed : null"
				align="center"
				:width="data.width ? data.width : getColumnWidth(data.prop)"
			>
				<template slot-scope="scope">
					<div>
						<el-tooltip
							v-if="
								(editIndex !== scope.row.index || !data.type || editField !== data.prop) &&
								['lon', 'lat', 'distanceSlr', 'timeSlr'].includes(data.prop) &&
								scope.row.masterReportInfo[data.prop + 'Consult']
							"
							class="item"
							effect="dark"
							:content="scope.row.masterReportInfo[data.prop + 'Consult']"
							placement="right"
						>
							<span style="color: red" @dblclick="editCells(scope.row.index, data.prop, data.type)">{{
								scope.row.masterReportInfo[data.prop]
							}}</span>
						</el-tooltip>
						<span
							v-else-if="editIndex !== scope.row.index || !data.type || editField !== data.prop"
							@dblclick="editCells(scope.row.index, data.prop, data.type)"
							>{{ scope.row.masterReportInfo[data.prop] }}</span
						>
						<!-- 输入框 -->
						<el-input
							class="lng-lat-input"
							v-else-if="data.type === 'input'"
							size="mini"
							v-model="scope.row.masterReportInfo[data.prop]"
							@click.native.stop
						></el-input>
						<!-- 下拉选择 -->
						<el-select
							class="lng-lat-input"
							v-else-if="data.type === 'select'"
							size="mini"
							v-model="scope.row.masterReportInfo[data.prop]"
							@click.native.stop
						>
							<el-option v-for="data in EWNS" :label="data" :value="data" :key="data" />
						</el-select>
						<!-- 时间 -->
						<mydatePicker
							v-else-if="data.type === 'time'"
							size="mini"
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="scope.row.masterReportInfo[data.prop]"
							@click.native.stop
						></mydatePicker>
						<!-- 经纬度 -->
						<el-popover
							v-else-if="data.type === 'Lat' || data.type === 'Lon'"
							placement="bottom-end"
							width="260"
							@show="lonLatShow(scope.row.masterReportInfo[data.prop], data.prop)"
							trigger="hover"
						>
							<p>{{ data.type === 'Lat' ? 'Latitude' : 'Longitude' }}</p>
							<div class="lonLatContent">
								<el-input class="lonLat" v-model="du" placeholder=""></el-input>°
								<el-input class="lonLat" v-model="fen" placeholder=""></el-input>′
								<el-button
									type="primary"
									icon="el-icon-check"
									@click="saveLatLon(scope.row.masterReportInfo, data.prop)"
								></el-button>
							</div>
							<el-input
								slot="reference"
								class="lng-lat-input"
								size="mini"
								v-model="scope.row.masterReportInfo[data.prop]"
								@click.native.stop
							></el-input>
						</el-popover>
					</div>
				</template>
				<el-table-column
					v-for="val in data.fieldList"
					:key="val.fieldName"
					:prop="val.prop"
					:label="val.fieldName"
					align="center"
					:min-width="getColumnWidth(val.prop)"
					:width="val.width ? val.width : 'auto'"
				>
					<template slot-scope="scope">
						<el-tooltip
							v-if="
								(editIndex !== scope.row.index || !val.type || editField !== val.prop) &&
								val.prop.includes('totalConsSlr') &&
								scope.row.masterReportInfo[val.prop + 'Consult']
							"
							class="item"
							effect="dark"
							:content="scope.row.masterReportInfo[val.prop + 'Consult'].toString()"
							placement="right"
						>
							<span style="color: red" @dblclick="editCells(scope.row.index, val.prop, val.type)">{{
								scope.row.masterReportInfo[val.prop]
							}}</span>
						</el-tooltip>
						<span
							v-else-if="editIndex !== scope.row.index || editField !== val.prop"
							@dblclick="editCells(scope.row.index, val.prop, val.type)"
						>
							<!-- {{ scope.row.masterReportInfo[val.prop + 'Consult'] }} -->
							{{ scope.row.masterReportInfo[val.prop] }}
						</span>
						<el-input
							class="lng-lat-input"
							v-else-if="val.type === 'input'"
							size="mini"
							v-model="scope.row.masterReportInfo[val.prop]"
							@click.native.stop
						></el-input>
						<el-select
							class="lng-lat-input"
							v-else-if="val.type === 'select'"
							size="mini"
							v-model="scope.row.masterReportInfo[val.prop]"
							@click.native.stop
						>
							<el-option v-for="data in EWNS" :label="data" :value="data" :key="data" />
						</el-select>
					</template>
				</el-table-column>
			</el-table-column>

			<el-table-column
				v-for="val in manageList"
				:key="val.prop"
				:prop="val.prop"
				:label="val.fieldName"
				fixed="right"
				align="center"
				:width="val.width"
			>
				<template slot-scope="scope">
					<div
						:class="[scope.row.masterReportInfo[val.prop] ? 'actButton' : '', 'manageButton']"
						v-if="scope.row.masterReportInfo.typeSon !== 'COSP'"
						@click="manageChange(scope.row.masterReportInfo, val.prop, scope.row.masterReportInfo[val.prop])"
					>
						{{ scope.row.masterReportInfo[val.prop] ? 'Y' : 'N' }}
					</div>
					<div v-else>-</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="MANAGE" width="180">
				<template slot-scope="scope">
					<div class="buttonList">
						<img
							class="button"
							src="@/assets/bottom-img/edit.png"
							width="14px"
							@click="editReportInfo(scope.row)"
							@click.stop
						/>
						<el-popconfirm
							confirm-button-text="Yes"
							cancel-button-text="No"
							icon="el-icon-info"
							icon-color="#f90"
							title="Are you sure delete this?"
							@confirm="del(scope.row)"
							@click.stop
						>
							<img class="button" slot="reference" src="@/assets/bottom-img/del.png" width="14px" />
						</el-popconfirm>
						<img
							class="button"
							src="@/assets/bottom-img/copy.png"
							width="14px"
							@click="copyInfo(scope.row)"
							@click.stop
						/>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import mapobj from '@/utils/mapw.js';

import { updateMasterReport, deleteMasterReport } from '@/api/basic';
export default {
	props: {
		reportTable: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			// 表格内部字段名列表
			// type包含 input || select || time || Lat || Lon || null
			fieldList: [
				{
					fieldName: 'Type',
					prop: 'typeSon',
					type: null,
					width: '100',
					fixed: 'left',
				},
				{
					fieldName: 'Date（UTC）',
					prop: 'dateUtc',
					type: 'time',
					width: '180',
					fixed: 'left',
				},
				{
					fieldName: 'LAT',
					prop: 'lat',
					type: 'Lat',
					width: '140',
				},
				{
					fieldName: 'LON',
					prop: 'lon',
					type: 'Lon',
					width: '140',
				},
				{
					fieldName: 'Time SLR',
					prop: 'timeSlr',
					type: 'input',
					width: '180',
				},
				{
					fieldName: 'Avg Speed SLR',
					prop: 'avgSpeedSlr',
					type: 'input',
					width: '150',
				},
				{
					fieldName: 'Dist SLR',
					prop: 'distanceSlr',
					type: 'input',
					width: '150',
				},
				{
					fieldName: 'Dist To Go',
					prop: 'distanceToGo',
					type: 'input',
					width: '150',
				},
				{
					fieldName: 'Total Cons',
					type: null,
					fieldList: [
						{
							fieldName: 'HFO',
							prop: 'totalConsSlrHfo',
							type: 'input',
						},
						{
							fieldName: 'LSFO',
							prop: 'totalConsSlrLsfo',
							type: 'input',
						},
						{
							fieldName: 'MDO',
							prop: 'totalConsSlrMdo',
							type: 'input',
						},
						{
							fieldName: 'MGO',
							prop: 'totalConsSlrMgo',
							type: 'input',
						},
					],
				},
				{
					fieldName: 'BROB',
					type: null,
					fieldList: [
						{
							fieldName: 'HFO',
							prop: 'brobHfo',
							type: 'input',
						},
						{
							fieldName: 'LSFO',
							prop: 'brobLsfo',
							type: 'input',
						},
						{
							fieldName: 'MDO',
							prop: 'brobMdo',
							type: 'input',
						},
						{
							fieldName: 'MGO',
							prop: 'brobMgo',
							type: 'input',
						},
					],
				},
				{
					fieldName: 'Draft',
					type: null,
					fieldList: [
						{
							fieldName: 'Mean',
							prop: 'midDraft',
							type: 'input',
						},
						{
							fieldName: 'Aft',
							prop: 'aftDraft',
							type: 'input',
						},
						{
							fieldName: 'Fore',
							prop: 'foreDraft',
							type: 'input',
						},
					],
				},
				{
					fieldName: 'Course',
					prop: 'course',
					type: 'input',
				},
				{
					fieldName: 'ME',
					type: null,
					fieldList: [
						{
							fieldName: 'MCR',
							prop: 'mcr',
							type: 'input',
						},
						{
							fieldName: 'SlipSLR',
							prop: 'slipSlr',
							type: 'input',
							width: '150',
						},
						{
							fieldName: 'MePowerSLR',
							prop: 'mePowerSlr',
							type: 'input',
							width: '150',
						},
						{
							fieldName: 'AvgRpmSLR',
							prop: 'avgRpmSlr',
							type: 'input',
							width: '150',
						},
					],
				},
				{
					fieldName: 'WIND',
					type: null,
					fieldList: [
						{
							fieldName: 'DIR',
							prop: 'windDirection',
							type: 'select',
						},
						{
							fieldName: 'BF',
							prop: 'windBeaufort',
							type: 'input',
						},
					],
				},
				{
					fieldName: 'SeaHT(m)',
					prop: 'seasHeight',
					type: 'input',
				},
				{
					fieldName: 'SWELL',
					type: null,
					fieldList: [
						{
							fieldName: 'DIR',
							prop: 'swellDirection',
							type: 'select',
						},
						{
							fieldName: 'HT(m)',
							prop: 'swellHeight',
							type: 'input',
						},
					],
				},
				{
					fieldName: 'ETA (UTC)',
					prop: 'nextPortEta',
					type: 'time',
					width: '200',
				},
				{
					fieldName: 'Next Port',
					prop: 'nextPortNextPort',
					type: 'input',
				},
			],
			// 操作字段
			manageList: [
				{
					fieldName: 'Not\nGW',
					prop: 'notGoodWx',
					event: '',
					width: '60',
				},
				{
					fieldName: 'DGO\n-FO',
					prop: 'dgoFo',
					event: '',
					width: '68',
				},
				{
					fieldName: 'in\nECA',
					prop: 'inEca',
					event: '',
					width: '68',
				},
				{
					fieldName: 'Exclude',
					prop: 'exclude',
					event: '',
					width: '90',
				},
			],
			EWNS: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
			tableData: [],
			// 经纬度
			du: '',
			fen: '',
			direction: '',
			tableHeight: 290,
			editIndex: -1,
			editField: null,
			voyageNo: '',
		};
	},
	computed: {
		getMaxHeight() {
			return document.body.clientHeight - 160;
		},
	},
	mounted() {
		this.$bus.$off('updateVoyageNo');
		this.$bus.$on('updateVoyageNo', (data) => {
			console.log('🚀 ~ this.$bus.$on ~ data:', data);
			this.voyageNo = data;
		});
	},
	methods: {
		// 单击单元格
		async selectRow(row) {
			console.log('🚀 ~ selectRow ~ row:', row);
			if (row?.isScrollTop) {
				const theTableRows = this.$refs.bottomTable.bodyWrapper.querySelectorAll(
					'.el-table__body tbody .el-table__row',
				);
				let scrollTop = 0;
				for (let i = 0; i < theTableRows.length; i++) {
					if (i === row.index) {
						break;
					}
					scrollTop += theTableRows[i].offsetHeight;
				}
				this.$refs.bottomTable.bodyWrapper.scrollTop = scrollTop;
				this.editIndex = this.reportTable.findIndex((item) => item.masterReportInfo.dateUtc === row.dateUtc);
			} else if (this.editIndex !== -1 && this.editField) {
				if (this.reportTable[this.editIndex].masterReportInfo.seg) {
					this.reportTable[this.editIndex].masterReportInfo.seg =
						this.reportTable[this.editIndex].masterReportInfo.seg.toString();
				}else{
					this.reportTable[this.editIndex].masterReportInfo.seg = ''
				}
				console.log(this.reportTable[this.editIndex]);
				let res = await updateMasterReport({
					...this.reportTable[this.editIndex],
					updateType: 'bottom',
					voyageNo: this.voyageNo ? this.voyageNo : '',
				});
				// 更新成功
				if (res.success) {
					console.log('🚀 ~ editRow ~ res:', res);
					// 编辑结束更新右侧表格
					this.$bus.$emit('editReportTable', res.data);
					this.$message.success('update success!');
				}
				this.editIndex = -1;
				this.editField = null;
			} else {
				this.editIndex = row.index;
				let lat = mapobj.ChangeToDu(row.masterReportInfo.lat.replace('′', ''));
				let lon = mapobj.ChangeToDu(row.masterReportInfo.lon.replace('′', ''));
				let latlng = L.latLng(lat, lon);
				// 将地图定位到指定的经纬度位置
				mapobj.Lmap.setView(latlng, 13); // 第二个参数是缩放级别，根据需要调整
			}
		},
		// 双击单元格
		editCells(index, fieldName, edit) {
			console.log('🚀 ~ editCells ~ index, fieldName, edit:', index, fieldName, edit);
			if (!edit) {
				return;
			}
			this.editIndex = index;
			this.editField = fieldName;
			console.log(this.editField);
		},
		manageChange(data, type, checked) {
			console.log('🚀 ~ manageChange ~ data, type, checked:', data, type, checked);
			data[type] = data[type] ? 0 : 1;
			console.log('🚀 ~ manageChange ~ data[type]:', data[type]);
		},
		// 显示数据
		lonLatShow(data, type) {
			type = type === 'lat' ? 1 : 0;
			this.direction = data.split('′')[1];
			const result = data.replace(/[WENS′]/g, '');
			let df = result.split('°');
			this.du = df[0];
			this.fen = df[1];
		},
		saveLatLon(data, type) {
			data[type] = this.du + '°' + this.fen + '′' + this.direction;
		},
		del(data) {
			console.log('🚀 ~ del ~ data:', data);
			deleteMasterReport({ id: data.id, voyageNo: this.voyageNo ? this.voyageNo : '' }).then((e) => {
				console.log('🚀 ~ deleteMasterReport ~ e:', e);
				this.$bus.$emit('editReportTable');
				this.editIndex = -1;
			});
		},
		// 编辑当前报文日报
		editReportInfo(allData) {
			let options = [
				{
					data: 'masterReportCosp',
					value: 'COSP',
					addType: 'cosp',
				},
				{
					data: 'masterReportNoonReport',
					value: 'Noon Report',
					addType: 'noonReport',
				},
				{
					data: 'masterReportEosp',
					value: 'EOSP',
					addType: 'eosp',
				},
				{
					data: 'masterReportStoppageReport',
					value: 'Drift',
					addType: 'stoppageReport',
				},
			];
			let selectValue = options.find((e) => e.addType === allData.typeSon).value;
			let infoData = options.find((e) => e.addType === allData.typeSon).data;
			this.$bus.$emit('copyReportTable', allData[infoData], selectValue, allData);
		},
		// 复制当前行
		copyInfo(data) {
			let options = [
				{
					data: 'masterReportCosp',
					value: 'COSP',
					addType: 'cosp',
				},
				{
					data: 'masterReportNoonReport',
					value: 'Noon Report',
					addType: 'noonReport',
				},
				{
					data: 'masterReportEosp',
					value: 'EOSP',
					addType: 'eosp',
				},
				{
					data: 'masterReportStoppageReport',
					value: 'Drift',
					addType: 'stoppageReport',
				},
			];
			let selectValue = options.find((e) => e.addType === data.typeSon).value;
			let infoData = options.find((e) => e.addType === data.typeSon).data;
			this.$bus.$emit('copyReportTable', data[infoData], selectValue);
		},
		closeBottom() {
			this.$emit('close');
		},
		/**
		 * 处理鼠标按下事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseDown(event) {
			// 禁止用户选择网页中文字
			document.onselectstart = () => false;
			// 禁止用户拖动元素
			document.ondragstart = () => false;
			// 保存鼠标最后移动的位置（Y轴）
			this.dragState = {
				// 鼠标开始移动的位置（Y轴）
				startMouseTop: event.clientY,
				// 鼠标最后移动的位置（Y轴）
				endMouseTop: event.clientY,
			};

			// 绑定鼠标移动事件
			document.addEventListener('mousemove', this.handleMouseMove);
			// 绑定鼠标放开事件
			document.addEventListener('mouseup', this.handleMouseUp);
		},

		/**
		 * 处理鼠标移动事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseMove(event) {
			const TextAreaWrap = {
				MaxHeight: document.body.clientHeight - 100,
				MinHeight: 150,
			};
			const { rWrap, rResizeLine } = this.$refs;
			// 获取鼠标最后移动的位置（Y轴）
			const { endMouseTop } = this.dragState;
			// 获取当前的文本框高度
			const oldTextAreaHeight = rWrap.getBoundingClientRect().height;
			// 新的文本框高度
			let newTextAreaHeight = 0;

			// 计算鼠标移动的距离
			const distance = Math.abs(parseInt(((endMouseTop - event.clientY) * 100).toString(), 10) / 100);

			// 若鼠标向上移动
			if (endMouseTop > event.clientY) {
				// 发送框高度达到最大
				if (oldTextAreaHeight >= TextAreaWrap.MaxHeight) {
					// 修改光标为可被向下移动
					rResizeLine.style.cursor = 's-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaHeight = oldTextAreaHeight + distance;
			}
			// 若鼠标向下移动
			else {
				// 发送框高度达到最小
				if (oldTextAreaHeight <= TextAreaWrap.MinHeight) {
					// 修改光标为可被向上移动
					rResizeLine.style.cursor = 'n-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaHeight = oldTextAreaHeight - distance;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
			if (newTextAreaHeight > TextAreaWrap.MaxHeight) {
				newTextAreaHeight = TextAreaWrap.MaxHeight;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
			if (newTextAreaHeight < TextAreaWrap.MinHeight) {
				newTextAreaHeight = TextAreaWrap.MinHeight;
			}

			// 修改发送框高度
			rWrap.style.height = newTextAreaHeight + 'px';
			// 修改光标为可移动
			rResizeLine.style.cursor = 'move';

			// 更新鼠标最后移动的位置（Y轴）
			this.dragState.endMouseTop = event.clientY;

			this.tableHeight = document.getElementsByClassName('drag-bottom1')[0].clientHeight - 70;
		},

		/**
		 * 处理鼠标放开事件
		 */
		handleMouseUp() {
			// 移除鼠标移动事件
			document.removeEventListener('mousemove', this.handleMouseMove);
			// 移除鼠标放开事件
			document.removeEventListener('mouseup', this.handleMouseUp);
			// 允许用户选择网页中文字
			document.onselectstart = null;
			// 允许用户拖动元素
			document.ondragstart = null;
		},
		tableRowClassName({ row, rowIndex }) {
			if (row) {
				row.index = rowIndex;
			}
			if (rowIndex === this.editIndex) {
				return 'edit-row';
			}
			return '';
		},
		getTableData(item) {
			this.$nextTick(() => {
				this.getTop();
			});
		},
		getTop() {
			let windowHeight = document.documentElement.clientHeight;
			let dom1Height = document.getElementsByClassName('bottom-content')[0].clientHeight;
			this.top = windowHeight - dom1Height;
			this.height = dom1Height;
		},
		// 自适应宽度单元格
		getColumnWidth(prop) {
			const minWidth = 85; // 最小宽度
			const padding = 5; // 列内边距
			const contentWidths = this.reportTable.map((item) => {
				const value = item.masterReportInfo[prop] ? String(item.masterReportInfo[prop]) : '';
				const textWidth = this.getTextWidth(value);
				return textWidth + padding;
			});
			const maxWidth = Math.max(...contentWidths);
			return Math.max(minWidth, maxWidth);
		},
		getTextWidth(text) {
			const span = document.createElement('span');
			span.style.visibility = 'hidden';
			span.style.position = 'absolute';
			span.style.top = '-9999px';
			span.style.whiteSpace = 'nowrap';
			span.innerText = text;
			document.body.appendChild(span);
			const width = span.offsetWidth + 5;
			document.body.removeChild(span);
			return width;
		},
	},
};
</script>
<style lang="scss" scoped>
.bottom-content {
	z-index: 999;
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(100vw);
	background: #eff1f5;
	height: 350px;

	.title {
		// cursor: move;
		display: flex;
		align-items: center;
		background: #eff1f5;
		border-bottom: 1px solid #d2dae4;
		padding: 10px 15px;
		position: relative;
		flex-direction: row-reverse;

		.resize-line {
			height: 6px;
			width: 40px;
			background: rgba(0, 0, 0, 0.2);
			cursor: move;
			background: #ccc;
			position: absolute;
			top: 3px;
			left: 50%;
			border-radius: 5px;
		}

		.field-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.field {
				font-size: 12px;
				margin-right: 30px;
				line-height: 22px;
				font-weight: bold;
			}
		}

		.btn-list {
			display: flex;
			align-items: center;

			img {
				cursor: pointer;
			}
		}
	}

	::v-deep .el-table {
		width: 100%;
		border-radius: 6px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 100% !important;
		}

		.el-table__header {
			.table-header {
				color: $defaultFontColor;
				font-weight: bold;

				// th {
				// 	background: #eff1f5;
				// }
			}
		}

		.edit-row {
			background: rgb(203, 234, 234) !important;
		}

		.el-table__row {
			.el-form-item {
				margin-bottom: 0;
				color: $defaultFontColor;

				.lng-lat-input {
					width: 240px !important;
				}
			}

			.el-button {
				color: #d9d9d9;
			}
		}
	}

	.manageButton {
		cursor: pointer;
		text-align: center;
		line-height: 28px;
		background: linear-gradient(0deg, #dcdcdc, #dcdcdc),
			linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
		border: 1px solid rgba(220, 220, 220, 1);
		width: 27px;
		height: 28px;
		padding: 3px, 8px, 3px, 8px;
		border-radius: 4px;
		border: 1px;
		gap: 10px;
	}

	.actButton {
		background: rgba(16, 70, 166, 1);
		color: #fff;
	}

	.buttonList {
		width: 120px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		.button {
			cursor: pointer;
		}
	}
}
::v-deep .el-table th.el-table__cell > .cell {
	//   white-space: pre;
	white-space: pre-wrap; // 也行。
}
.lonLatContent {
	display: flex;
	width: 100%;
}
</style>
